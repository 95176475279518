import styled from 'styled-components';
import logo from '../../../assets/stripecon_logo.svg';
import {Link} from "react-router-dom";
import media from "../../../utilities/media.js";
import {copytext} from "../../../styles/typography.js";

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  margin: 0 auto;
  height: 80px;
  background-color: #fff;

  ${media.moreThan("md")} {
    height: 100px;
    padding: 20px 0;
  }
`

const Logo = styled(Link)`
  height: 100%;

  img {
    display: block;
    height: 100%;
  }
`;

const Languages = styled.div`
`;

const LanguageLink = styled.a`
  ${copytext};
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px;
`;

const Header = ({config, lang}) => {
  const languages = config.languages;
  return <HeaderStyled>
    <Logo to="/">
      <img src={logo} alt="Thyssenkrupp Logo"/>
    </Logo>
    <Languages>
      {languages.filter((language) => language != lang).map((language, index) => (
        <LanguageLink href={`/${language}`} key={`lang-${index}`}>{language}</LanguageLink>
      ))}
    </Languages>
  </HeaderStyled>
}

export default Header;