import {css} from "styled-components";
import calcrem from "../utilities/calcrem/calcrem";
import media from "../utilities/media";
/*
  font-weight: 300/600 only!
*/
const headline1 = css`
  font-family: ${props => props.theme.fonts.Headline};
  font-size: ${calcrem(28)};
  line-height: ${calcrem(37)};
  font-weight: bold;
  color: ${props => props.theme.colors.text};
  margin-bottom: 20px;

  ${media.moreThan("md")} {
    font-size: ${calcrem(44)};
    line-height: ${calcrem(50)};
    margin-bottom: 30px;
  }
`;

const copytext = css`
  font-family: ${props => props.theme.fonts.Copy};
  font-size: ${calcrem(16)};
  line-height: ${calcrem(26)};
  color: ${props => props.theme.colors.text};

  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    border-bottom: 1px solid #fff;
  }

  strong {
    font-weight: bold;
  }

  p {
    font-size: ${calcrem(16)};
    line-height: ${calcrem(26)};
    margin-bottom: 20px;
  }

  ${media.moreThan("md")} {
    font-size: ${calcrem(16)};
    line-height: ${calcrem(21)};
  }

  ul,
  ol {
    padding: 0 0 0 27px;

    li {
      list-style-type: disc;
        //color: ${props => props.theme.colors.primary};
    }
  }
`;

const footertext = css`
  font-family: ${props => props.theme.fonts.Copy};
  font-size: ${calcrem(16)};
  line-height: ${calcrem(20)};
  color: ${props => props.theme.colors.primary};
  
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }
`

const buttontext = css`
  ${copytext};
  color: ${props => props.theme.colors.white};
  font-weight: normal;
  text-decoration: none;
  
  &:hover {
    color: ${props => props.theme.colors.grey};
  }
`;


export {headline1, copytext, buttontext, footertext};
