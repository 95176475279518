import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";
import media from "../../../utilities/media.js";

const ButtonStyled = styled.a`
  padding: 10px 20px;
  border-radius: 10px;
  ${buttontext};
  transition: color 0.3s ease-in-out;
  background-color: ${props => props.theme.colors.black};
  white-space: nowrap;
  
  & + & {
    margin-left: 10px;
  }
  
  // ${media.lessThan("lg")} {
  //   background-color: ${props => props.theme.colors.primary};
  //   color: ${props => props.theme.colors.white};
  //
  //   ${props => props.primary && `
  //     background-color: ${props.theme.colors.highlight};
  //     color: ${props.theme.colors.white};
  //   `};
  //
  //   ${props => props.disabled && `
  //     background-color: ${props.theme.colors.grey};
  //     color: ${props.theme.colors.darkGrey};
  //   `}
  // }

  ${props => props.disabled && `
    background-color: ${props.theme.colors.grey};
    color: ${props.theme.colors.darkGrey};
    
    &:hover {
      color: ${props.theme.colors.darkGrey};
    }
  `}
`

const ButtonHref = ({children, href, disabled, primary, download}) => {
  return <ButtonStyled
    primary={primary}
    href={href}
    disabled={disabled}
    download={download}
    target="_blank"
    onClick={(e) => {
      if (disabled) {
        e.preventDefault();
      }
    }}>
    {children}
  </ButtonStyled>
}

export default ButtonHref;