import create from 'zustand'

import filter1 from "../../assets/filter/filter1.png"
import filter2 from "../../assets/filter/filter2.png"
import filter3 from "../../assets/filter/filter3.png"
import filter4 from "../../assets/filter/filter4.png"
import filter5 from "../../assets/filter/filter5.png"
import filter6 from "../../assets/filter/filter6.png"
import filter7 from "../../assets/filter/filter7.png"

const filters = [
  { image: filter1 },
  { image: filter2 },
  { image: filter3 },
  { image: filter4 },
  { image: filter5 },
  { image: filter6 },
  { image: filter7 },
]

const useApplicationState = create((set) => ({
  conditions: false,
  setConditions: (conditions) => set({ conditions }),
  image: null,
  setImage: (image) => set({ image }),
  selectedDeviceId: undefined,
  setSelectedDeviceId: (selectedDeviceId) => set({ selectedDeviceId }),
  cropperState: null,
  setCropperState: (cropperState) => set({ cropperState }),
  cropperTransitions: null,
  setCropperTransitions: (cropperTransitions) => set({ cropperTransitions }),
  cropperImage: null,
  setCropperImage: (cropperImage) => set({ cropperImage }),
  filterMenu: filters,
  selectedFilter: filters[0],
  setSelectedFilter: (selectedFilter) => set({ selectedFilter }),
  zoom: 1,
  setZoom: (zoom) => set({ zoom }),
  editedImage: null,
  setEditedImage: (editedImage) => set({ editedImage }),
}));

export default useApplicationState;