import styled from 'styled-components';
import {copytext} from "../../../styles/typography.js";
import media from "../../../utilities/media.js";

const CheckboxContentStyled = styled.div`
  flex: 1 1 auto;
  margin-bottom: 20px;

  ${media.moreThan("lg")} {
    width: 100%;
  }
`

const FakeCheckbox = styled.div`
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  // border: 1px solid ${props => props.theme.colors.primary};
  //
  // ${media.moreThan("lg")} {
  //   background-color: ${props => props.theme.colors.white};
  // }

  background-color: ${props => props.theme.colors.white};

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 20px;
    border-right: 1px solid ${props => props.theme.colors.primary};
    transform-origin: center right;
    transform: rotate(45deg);
    display: none;
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const CheckboxStyled = styled.input`
  display: none;
  
  &:checked + label ${FakeCheckbox} {
    &:before, &:after {
      display: block;
    }
  }
`;

const LabelStyled = styled.label`
  display: block;
  padding-left: 30px;
  position: relative;
  ${copytext};
  cursor: pointer;
  
  // ${media.lessThan("lg")} {
  //   color: ${props => props.theme.colors.primary};
  //  
  //   a {
  //     color: ${props => props.theme.colors.primary};
  //     border-bottom:1px solid ${props => props.theme.colors.primary};
  //   }
  // }
`;

const Checkbox = ({ name, label, checked, onChange }) => {
  return <CheckboxContentStyled>
    <CheckboxStyled type="checkbox" name={name} id={name} value={1} checked={checked} onChange={onChange}/>
    <LabelStyled htmlFor={name}>
      <FakeCheckbox/>
      <span dangerouslySetInnerHTML={{__html: label}}/>
    </LabelStyled>
  </CheckboxContentStyled>
}

export default Checkbox;