import font from '../assets/SwedenSansRegular.ttf';
import fontBold from '../assets/SwedenSansBold.ttf';

const fonts = `
  @font-face {
    font-family: SwedenSans;
    src: url(${font}) format("truetype");
    font-style: normal
  }
  @font-face {
    font-family: SwedenSans;
    src: url(${fontBold}) format("truetype");
    font-weight: 700;
    font-style: bold
  }
`;

export default fonts;