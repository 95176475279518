import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";

const ButtonStyled = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: ${props => props.theme.colors.black};
  border-radius: 10px;
  cursor: pointer;
  ${buttontext};
`

const Button = ({ children, onClick, primary }) => {
  return <ButtonStyled onClick={() => onClick()} primary={primary}>
    {children}
  </ButtonStyled>
}

export default Button;