import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import Text from "../../atoms/Text/Text.jsx";
import Content from "../../atoms/Content/Content.jsx";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import ButtonLink from "../../atoms/ButtonLink/ButtonLink.jsx";
import {base_url} from "../../../App.jsx";
import ButtonHref from "../../atoms/ButtonHref/ButtonHref.jsx";
import ButtonShare from "../../atoms/ButtonShare/ButtonShare.jsx";
import {useNavigate} from "react-router-dom";
import Button from "../../atoms/Button/Button.jsx";


const FinishedPageStyled = styled.div`
`

const FinishedPage = ({ lang, config, hash}) => {
  const navigate = useNavigate();
  const frontend_url = location.protocol + '//' + location.host;

  const restart = () => {
    navigate(`/${lang}/camera`);
  }

  return <FinishedPageStyled>
    <Content>
      <Headline>{ config.FinishTitle }</Headline>
      <Text content={ config.FinishContent } />
    </Content>
    <ButtonRow>
      <ButtonHref href={`${base_url}/assets/Uploads/${hash}.jpg`} download={`${hash}.jpg`}>{config.ButtonDownload}</ButtonHref>
      <Button onClick={restart}>{config.ButtonRestart}</Button>
    </ButtonRow>
    <ButtonRow>
      <ButtonShare link={`${frontend_url}/${lang}?id=${hash}`} title={config.SharingTitle} text={config.SharingDescription}>{config.ButtonShare}</ButtonShare>
    </ButtonRow>
  </FinishedPageStyled>
}

export default FinishedPage;