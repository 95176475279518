import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";
import iconfb from "../../../assets/icon_fb.svg";
import icontwitter from "../../../assets/icon_twitter.svg";
import iconlinkedin from "../../../assets/icon_linkedin.svg";
import iconmail from "../../../assets/icon_mail.svg";
import {useState} from "react";

const ButtonContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

const ButtonStyled = styled.button`
  height: 41px;
  padding: 10px 20px;
  border: none;
  background-color: ${props => props.theme.colors.black};
  border-radius: 10px;
  cursor: pointer;
  ${buttontext};
`

const IconContainer = styled.div`
  overflow: hidden;
`;

const IconContent = styled.div`
  //padding-left: 8px;
  margin-left: -5px;
  transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;
`;

const IconLink = styled.a`
  display: inline-block;
  height: 41px;
  //width: 51px;
  padding: 1px 5px;
  
  ${props => props.linkedin && `padding: 2px 6px;`}
  
  img {
    height: 100%;
  }
`;

const ButtonShare = ({children, primary, link, title, text}) => {
  const [open, setOpen] = useState(false);

  const encodedLink = encodeURIComponent(link);
  const encodedText = encodeURIComponent(text);
  const encodedTitle = encodeURIComponent(title);

  return <ButtonContainer>
    {/*<ButtonStyled onClick={() => setOpen(!open)} primary={primary}>*/}
    {/*  {children}*/}
    {/*</ButtonStyled>*/}
    <IconContainer>
      <IconContent open>
        <IconLink href={`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`} target="_blank">
          <img src={iconfb} alt="Facebook"/>
        </IconLink>
        <IconLink href={`https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedLink}`} target="_blank">
          <img src={icontwitter} alt="Twitter"/>
        </IconLink>
        <IconLink href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedLink}`} linkedin target="_blank">
          <img src={iconlinkedin} alt="LinkedIn"/>
        </IconLink>
        <IconLink href={`mailto:?subject=${encodedTitle}&body=${encodedText}%0D%0A%0D%0A${link}`} target="_blank">
          <img src={iconmail} alt="Email"/>
        </IconLink>
      </IconContent>
    </IconContainer>
  </ButtonContainer>
}

export default ButtonShare;