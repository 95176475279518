import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import {Link, Navigate, useParams} from "react-router-dom";
import useApplicationState from "../../../utilities/ApplicationState/ApplicationState.jsx";
import Text from "../../atoms/Text/Text.jsx";
import { CropperPreview } from 'react-advanced-cropper'
import FilterNav from "../../molecules/FilterNav/FilterNav.jsx";
import Content from "../../atoms/Content/Content.jsx";
import Checkbox from "../../atoms/Checkbox/Checkbox.jsx";
import ButtonLink from "../../atoms/ButtonLink/ButtonLink.jsx";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import media from "../../../utilities/media.js";
import Button from "../../atoms/Button/Button.jsx";
import React from "react";


const FilterContent = styled.div`
  ${media.moreThan("lg")} {
    padding-top: 20px;
  }
`;

const FilterPageStyled = styled.div`
  ${media.moreThan("lg")} {
    display: flex;
    flex-direction: column;
    
    ${FilterContent} {
      order: 2;
    }
  }
`

const FilterPage = ({ image, cropperImage, cropperState, cropperTransitions, filterMenu, config, finish}) => {

  const {lang} = useParams();

  return <FilterPageStyled>
    {!image && <Navigate to={`/${lang}/camera`}/>}
    <FilterContent>
      <FilterNav
        image={cropperImage}
        state={cropperState}
        transitions={cropperTransitions}
        filters={filterMenu}
      />
    </FilterContent>

    <Content>
      <Headline>{ config.FilterTitle }</Headline>
      <Text content={config.FilterContent} />
    </Content>
    <ButtonRow>
      <Button onClick={finish} primary>{config.ButtonFinish}</Button>
    </ButtonRow>
  </FilterPageStyled>
}

export default FilterPage;
