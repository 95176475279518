import styled from 'styled-components';
import { FixedCropper, ImageRestriction } from 'react-advanced-cropper'
import Square from "../../atoms/Square/Square.jsx";
import useApplicationState from "../../../utilities/ApplicationState/ApplicationState.jsx";
import 'react-advanced-cropper/dist/style.css';

import Overlay from "../../atoms/Overlay/Overlay.jsx";

import {forwardRef, useEffect, useRef} from "react";

const PreviewStyled = styled.div`
  display: flex;
  width: 100%;

  .react-transform-wrapper {
    height: 100%;
  }

  .advanced-cropper {
    height: 100%;
  }
`

const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageEditor = forwardRef(({ zoom }, ref) => {

  const image = useApplicationState((state) => state.image);
  const setCropperState = useApplicationState((state) => state.setCropperState);
  const setCropperTransitions = useApplicationState((state) => state.setCropperTransitions);
  const setCropperImage = useApplicationState((state) => state.setCropperImage);
  const selectedFilter = useApplicationState((state) => state.selectedFilter)

  // Todo: Slider  https://github.com/advanced-cropper/react-advanced-cropper/tree/master/example/src/components/showcase/Twitter/components

  const stencilSize = ({ boundary }) => {
    return {
      width: Math.min(boundary.height, boundary.width),
      height: Math.min(boundary.height, boundary.width),
    };
  };

  const defaultSize = ({ imageSize }) => {
    return {
      width: Math.min(imageSize.height, imageSize.width),
      height: Math.min(imageSize.height, imageSize.width),
    };
  };

  const onChange = (cropper) => {
    setCropperState(cropper.getState());
    setCropperImage(cropper.getImage());
    setCropperTransitions(cropper.getTransitions());
  };

  const onTransitionsChange = (cropper) => {
  };

  // useEffect(() => {
  //   if (image && ref) {
  //     setCropperState(ref.current.getState());
  //     setCropperImage(ref.current.getImage());
  //     setCropperTransitions(ref.current.getTransitions());
  //   }
  // }, [image, ref]);

  return <PreviewStyled>
    <Square rounded>
      <FixedCropper
        src={image}
        defaultSize={defaultSize}
        stencilProps={{
          handlers: false,
          lines: false,
          movable: false,
          resizable: false,
        }}
        stencilSize={stencilSize}
        imageRestriction={ImageRestriction.stencil}
        sizeRestrictions={{
          minWidth: 1000,
        }}
        onChange={onChange}
        // onReady={onChange}
        // onMoveEnd={onTransitionsChange}
        // onTransitionsEnd={onTransitionsChange}
        ref={ref}
      />
      <Overlay src={selectedFilter.image}/>
    </Square>
  </PreviewStyled>;
});

export default ImageEditor;