import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import Text from "../../atoms/Text/Text.jsx";
import Content from "../../atoms/Content/Content.jsx";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import Checkbox from "../../atoms/Checkbox/Checkbox.jsx";
import ButtonLink from "../../atoms/ButtonLink/ButtonLink.jsx";
import {useParams} from "react-router-dom";


const HomePageStyled = styled.div`
`

const HomePage = ({ conditions, setConditions, config }) => {

  const { lang } = useParams();

  return <HomePageStyled>
    <Content>
      <Headline>{config.HomeTitle}</Headline>
      <Text content={config.HomeContent} />
    </Content>
    <ButtonRow>
      <ButtonLink to={`/${lang}/camera`}>{config.ButtonContinue}</ButtonLink>
    </ButtonRow>
  </HomePageStyled>
}

export default HomePage;