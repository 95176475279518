const defaultTheme = {
  colors: {
    black: "#000",
    white: "#fff",
    whiteTrans: "rgba(255,255,255,0.9)",
    grey: " #ccc",
    darkGrey: " #999",
    text: "#000000",
    primary: "#000",
    background: "#f6ed7f",
    highlight: "#ffbf26",
  },
  fonts: {
    Headline: "SwedenSans, Helvetica, Arial, sans-serif",
    Button: "SwedenSans, Helvetica, Arial, sans-serif",
    Copy: "SwedenSans, Helvetica, Arial, sans-serif"
  }
};

export default defaultTheme;
