import styled from 'styled-components';
import Icon, {IconStyled} from "../Icon/Icon.jsx";
import {buttontext} from "../../../styles/typography.js";

const ButtonStyled = styled.button`
  -webkit-appearance: none;
  //width: 50px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: ${props => props.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  ${buttontext};
  text-transform: uppercase;
  white-space: nowrap;
  padding: 5px 15px 5px 10px;
  
  ${IconStyled} {
    height: 100%;
    margin-right: 10px;
  }
`

const Button = ({ icon, children, onClick }) => {
  return <ButtonStyled onClick={() => onClick()}>
    <Icon id={icon}/>
    { children }
  </ButtonStyled>
}

export default Button;