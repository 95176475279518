import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";

const SelectStyled = styled.select`
  padding: 5px;
  background-color: ${props => props.theme.colors.black};
  ${buttontext}
`;

const Select = ({ children, value, onChange }) => {
  return <SelectStyled value={value} onChange={onChange}>
    {children}
  </SelectStyled>
};

export default Select;