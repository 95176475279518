import styled from 'styled-components';
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import media from "../../../utilities/media";

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  //background-color: ${props => props.theme.colors.background};
  width: calc(100% - 40px);
  margin: 0 20px;
  border-radius: 20px;

`;

const MainStyled = styled.div`
  flex: 1 0 auto;
  width: 100%;
  //max-width: 1000px;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.background};
  border-radius: 16px;
  overflow: hidden;
  padding: 20px;

  ${media.moreThan('lg')} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
  }

`;

const Content = styled.div`
  padding: 0 20px;

  ${media.moreThan('lg')} {
    width: 50%;
    max-width: 500px;
    padding-left: 40px;
  }
`;

const Preview = styled.div`
  position: relative;

  ${media.moreThan('lg')} {
    width: 50%;
    max-width: 500px;
  }

`;

const Layout = ({children, preview, config, lang}) => {
  return <LayoutStyled>
    <Header config={config} lang={lang}/>
    <MainStyled>
      <Preview>
        {preview}
      </Preview>
      <Content>
        {children}
      </Content>
    </MainStyled>
    <Footer/>
  </LayoutStyled>
}

export default Layout;