import styled from 'styled-components';
import media from "../../../utilities/media.js";

import image from "../../../assets/placeholder_filter.jpg";
import Square from "../../atoms/Square/Square.jsx";
import Image from "../../atoms/Image/Image.jsx";

const PlaceholderStyled = styled.div`
  margin-bottom: 20px;

  ${media.moreThan("lg")} {
    margin-bottom: 50px;
  }
`;

const Placeholder = ({ entry }) => {
  return (
    <PlaceholderStyled>
      <Square rounded>
        <Image src={entry || image}/>
      </Square>
    </PlaceholderStyled>
  );
}

export default Placeholder;