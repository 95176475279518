import styled from 'styled-components';
import {footertext} from "../../../styles/typography.js";

const FooterStyled = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  padding: 20px;
`

const FooterText = styled.div`
  text-align: left;
  ${footertext}
`;

const Footer = () => {
  return <FooterStyled>
    <FooterText>
      <a href="https://www.stripecon.eu/" target="_blank">StripeCon</a> / <a href="https://www.s2-hub.com/" target="_blank">S2-Hub</a> / <a href="https://www.adiwidjaja.com/" target="_blank">ATW</a>
    </FooterText>
  </FooterStyled>
}

export default Footer;