import styled, {css, keyframes} from 'styled-components';
import Webcam from "react-webcam";
import React, {forwardRef, useRef} from "react";
import useApplicationState from "../../../utilities/ApplicationState/ApplicationState.jsx";
import Square from "../../atoms/Square/Square.jsx";
import {useNavigate, useParams} from "react-router-dom";
import ImageEditor from "../ImageEditor/ImageEditor.jsx";
import CameraButton from "../../atoms/CameraButton/CameraButton.jsx";
import Select from "../../atoms/Select/Select.jsx";
import media from "../../../utilities/media.js";
import CameraButtonRow from "../../atoms/CameraButtonRow/CameraButtonRow.jsx";
import Button from "../../atoms/Button/Button.jsx";
import {copytext} from "../../../styles/typography.js";

const CameraStyled = styled.div`
  width: 100%;
  margin-bottom: 20px;

  ${media.moreThan("lg")} {
    margin-bottom: 50px;
  }
`

const WebcamStyled = styled(Webcam)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  
`;

const Recording = styled.div`
  width: 100%;
  position: relative;
`;

const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const flash = keyframes`
  0% {
    opacity: 1;
  }
  1% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
`

const Flash = styled(Square)`
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 100;
  pointer-events: none;

  ${props => props.image && css`
    animation: ${flash} 1.5s ease-in-out;
  `}
`;

const CameraSelectorContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const NoPermission = styled.div`
  ${copytext};
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const BelowCamera = styled.div`
  margin-top: 40px;

  ${media.lessThan("lg")} {
    display: none;
  }
`;

const Camera = forwardRef((
  {
    image,
    setImage,
    selectedDeviceId,
    setSelectedDeviceId,
    selectedFilter,
    setEditedImage,
    config,
    finish,
  }, cropperRef) => {
  const [devices, setDevices] = React.useState([]);
  const [mediaPermission, setMediaPermission] = React.useState(false);

  const navigate = useNavigate();
  const {lang} = useParams();

  const handleDevices = React.useCallback(
    mediaDevices => {
      const devices = mediaDevices.filter(({kind}) => kind === "videoinput")
      setDevices(devices);
      if (!selectedDeviceId && devices.length > 0) {
        setSelectedDeviceId(devices[0].deviceId);
      }
    },
    [setDevices]
  );

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      setImage(webcamRef.current.getScreenshot());
      navigate(`/${lang}/filter`);
    },
    [webcamRef]
  );

  React.useEffect(() => {
    if (!navigator.mediaDevices?.getUserMedia) {
      setMediaPermission(false);
      return;
    }
    navigator.mediaDevices.getUserMedia({
      video: true,
    }).then(() => {
      setMediaPermission(true);
    });
  }, [])

  React.useEffect(
    () => {
      if (!navigator.mediaDevices?.enumerateDevices) {
        setDevices([]);
        setSelectedDeviceId(null);
        return;
      }
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices, mediaPermission]
  );

  const videoConstraints = {
    // facingMode: "user",
    deviceId: selectedDeviceId
  };

  const repeatPermission = () => {
    navigator.mediaDevices.getUserMedia({
      video: true,
    }).then(() => {
      setMediaPermission(true);
    });
  }

  return <CameraStyled>
    <Flash image={image}/>
    {image ? (
      <div>
        <Square background="transparent">
          <ImageEditor image={image} ref={cropperRef}/>
          <CameraButtonRow>
            <CameraButton onClick={() => cropperRef.current.zoomImage(0.8)} icon="minus">Zoom out</CameraButton>
            <CameraButton onClick={() => {
                return setImage(null);
            }} icon="repeat">Repeat</CameraButton>
            <CameraButton onClick={() => cropperRef.current.zoomImage(1.2)} icon="plus">Zoom in</CameraButton>
          </CameraButtonRow>
        </Square>
      </div>
    ) : (
      <Recording>
        <Square background="transparent" rounded>
          {mediaPermission ? (
            <>
              <WebcamStyled
                videoConstraints={videoConstraints}
                ref={webcamRef}
                mirrored
                screenshotFormat="image/jpeg"
                onClick={capture}
                minScreenshotHeight={1080}
                minScreenshotWidth={1920}
              />
            </>
          ) : (
            <NoPermission onClick={() => repeatPermission()}>
              Keine Erlaubnis erteilt.
            </NoPermission>
          )}
        </Square>
        {devices.length > 1 && (
          <CameraSelectorContainer>
            <Select onChange={(event) => setSelectedDeviceId(event.target.value)} value={selectedDeviceId}>
              {devices.map((device, key) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Device ${key + 1}`}
                </option>))}
            </Select>
          </CameraSelectorContainer>
        )}
        {mediaPermission && (
          <CameraButtonRow>
            <CameraButton onClick={capture} icon="camera">Photo</CameraButton>
          </CameraButtonRow>
        )}
      </Recording>
    )}
  </CameraStyled>
})

export default Camera;